// @import '~antd/dist/antd.less';
@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';
@import './theme.less';
@import './SFProText/style.css';
@import './variables.less';
@import './custom.less';
// @import '@fontsource/source-sans-pro';

iframe {
  display: none
}
body {
  margin: 0;
  // font-family: 'SFProText Regular', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
  //   'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif,'source sans';
    font-family: "Source Sans Pro";
    
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.searchbar>span>input{
  border-radius: 18px 0px 0px 18px;
}
// .searchbar>span>input::after{
//   border-radius: 18px;
// }
.searchbar>span>span>button{
  // display:none;
  border-radius: 0px 18px 18px 0px !important;
}
// .customLable>div>div>div>div>div{
//   border-radius: 18px !important;
// }
// .customLable>div>div>div>input{
//   border-radius: 18px !important;
// }
