.upper_card_container {
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(127, 161, 250, 0.15);
    border-radius: 10px;
    height: 180px;
    padding-top: 25px;
    padding-bottom: 45px;
    padding-left: 15px;
    position: relative;
  }
  
  .lower_card_container {
    padding-top: 80px;
    padding-bottom: 110px;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(127, 161, 250, 0.15);
    border-radius: 10px;
    height: 440px;
  }
  
  .left_greeting {
    position: relative;
    width: 30%;
    font-family: 'SFProText Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 29px;
    line-height: 36px;
    color: #ffffff;
    float: left;
  }
  
  .vertical_divider {
    float: right;
    border: 1px dashed #cfd7df;
    height: 230.41px;
  }
  
  .item {
    vertical-align: top;
    display: inline-block;
    text-align: center;
  }
  
  .caption {
    padding: 20px 0 15px 0;
    font-family: 'SFProText Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
  
    color: #333333;
  
    display: block;
  }
  
  .description {
    display: block;
    font-family: 'SFProText Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* or 150% */
  
    text-align: center;
  
    color: #666666;
    padding-bottom: 15px;
  }
  
  .lower_card_button {
    color: white;
  
    background-color: #fff;
    width: auto;
    height: 39px;
  }
  
  .upper_card_right_side {
    position: relative;
    white-space: nowrap;
    color: white;
  }
  .upper_card_divider {
    margin-right: 40px;
    width: 2px;
    height: 75px;
    background: #fff;
  }
  .upper_card_right_side_b {
    position: relative;
    white-space: nowrap;
    color: white;
    font-size: 40px;
    font-weight: 600;
  }
  
  .left_triangle {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: 6px 0 0 0;
  }
  
  .full_square {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: 0;
  }
  
  .hr_dots {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 35px 0px 0px 85px;
  }
  
  .flipped_triangle1 {
    position: absolute;
    float: right;
    top: 0;
    bottom: 0;
    right: 30px;
  }
  
  .flipped_triangle2 {
    position: absolute;
    float: right;
    top: 0;
    bottom: 0;
    right: 50px;
  }
  
  .vr_dots {
    position: absolute;
    float: right;
    top: 40px;
    bottom: 0;
    right: 30px;
  }
  
  .bt_dots {
    bottom: 6px;
    left: 50%;
    position: absolute;
  }
  // .inwardbutton > span{
  //   margin-left: 35px;
  //   margin-right: 35px;
  // }
  .cardbody{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    border-radius: 15px;
    text-align: center;
  }

  .cardbody:hover{
    transform: scale(1.1);
  }

  .cardbody1 > div >div >div{
    width:100% !important;
  }
  .inwardbutton:hover{
    color:black !important;
    
  }
  .inwardbutton{
    // padding-left: 50px;
    // padding-right: 70px;
    // margin-left: -40px !important;
    border-radius: 8px;
    font-size: 14px;
    background-color: #f1f1f1;
    width: 100% !important;
  }
  .fileback{
    width: 56px;
  height: 56px;
  
  background: #FFBAD7;
  border: 10px solid #FFDEEC;
  border-radius: 28px;
  
  /* Inside auto layout */
  
  flex: none;
  order: 0;
  flex-grow: 0;
  }
  .cardStyles_approver > div >div >div{
    color: #c91761;
    font-weight: bold;
  }
  .cardStyles_approver{
    border-radius: 18px ;
    // background-color: red;
  }
  .cardStyles_approver>div:first-child{
    border-radius: 18px 18px 0px 0px;
    background-color: #FFF3F8;
  }
  .table_container {
    margin-top: 16px;
    margin-left: 0px !important;
  }
  .table_container th {
    font-size: 14px;
    //
  }
  .table_container td {
    font-size: 14px;
  }
  @media screen and (max-width: 480px) {
    .inwardbutton {
      font-size: 9px;
    }
  }
  

  .stickers{
    height: 40%;
    width: 40%;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .cardbody >div >div>div:nth-child(2)>span{
    font-size: 14px;
    font-weight: 600;
  }
  .BGimage{
    padding: 0px;
    background-image: url('../../../src/images/graph.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center bottom;
    height: 100%;
    position: relative;
  }