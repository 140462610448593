.radiofont > div > label{
    font-size: 14px !important;
  }
  
  .customLable{
  font-style: normal;
//   font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #263238;
  :global(.ant-input[disabled])
    {
      color: black !important;
    }
  }
  .CustomPanel{
  font-style: normal;
//   font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  border-radius: 18px !important;
  }

  .CustomPanel > div:first-child{
    border-radius: 18px 18px 0px 0px;
    background-color: #FFF3F8;
  }

  .cardStyles_approver2{
    border-radius: 18px !important; 
    overflow: hidden;
    background-color: #6c6c7066;
  }
  .cardStyles_approver1 >{
// border-radius: 18px;
  }
  .cardStyles_approver1 > div:first-child{
    border-radius: 18px 18px 0px 0px !important;
    background-color: #FFF3F8;
  }

  .CustomPanel > div > span {
  
    color: #C91761 !important;
  }
  .customRadio >label{
    font-size: 14px;
  }
  .customLable >div> label{
    font-style: normal;
    // font-weight: bold;
    width:100%;
    font-size: 14px;
    line-height: 20px;
    color: #263238;
    }
    .customLable >div:nth-child(2)>div>div>div>div>span:nth-child(2) {
      font-size: 14px !important;
    }
    .customLable >div:nth-child(2)>div:nth-child(2)>div {
      color: #C91761;
      font-size: 14px;
  
    }
    .CustomCard >div >div>div{
      color: #c91761;
    //   font-weight: bold;
    }
    .CustomPanel >div >div >div{
      color: #c91761;
    //   font-weight: bold;
    }
    .inwardButton{
      border-radius: 8px;
  font-size: 14px;
  // background-color: #f1f1f1;
  width: 100% !important;
    }
    .customValue{
      color: #858C94;
      font-size: 14px;
    }
    .lable{
        // font-weight: bold;
        font-size: 14px;
      }
      @media (max-width: 480px)  {
      font-size: 14px;
    }
    .cardWidth{
        width: 100% !important;
    }
    @media (min-width: 720px)  {
    .qr{
        margin-top: 23px;
    }
  }
  .qrcode{
    margin-top: 23px;
  }

  .qrcode1{
    margin-top: 23px;
    font-weight: bold;
  } 

  .qrcode1:hover{
    margin-top: 23px;
    background-color: #e80e94 !important;
    border-color: #e80e94 !important;
    color:#fff !important;
    font-weight: bold;
  }
  .read{
    margin-top: 34px;
    font-size: 14px;
  }
  .read1{
    color:#c91761
  }

  .read1:hover{
    transform: scale(1.5);
  }
  .btn_resp{
    width: '100%';
    border-color: #28a745;
    color: #28a745;   
  }
  @media (max-width: 480px) 
  {
    .btn_resp{
      width: '80%';
      border-color: #28a745;
      color: #28a745;   
    }
  }
  
  @media(max-width:480px)
  {
    .CustomPanel >div>div>div{
      font-size: 14px;
    }
  }
  @media(max-width: 480px)
  {
    .qrcode{
      margin-left: -4px !important;
    }
  }
  .CustomPanel1{
    border-radius: 18px;
  }
  .cardStyles_approver{
    border-radius: 18px !important;
  }
  .customLable>div>div>div>div{
    border-radius: 12px;
  }
  .customLable>div>div>div>div>div{
    border-radius: 12px;
  }
  .customLable>div>div>div>input{
    border-radius: 12px;
  }
  .border_button1{
    border-radius: 8px;
    margin-top: 26px;
  }