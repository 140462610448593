.upper_card_container {
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(127, 161, 250, 0.15);
  border-radius: 10px;
  height: 180px;
  padding-top: 25px;
  padding-bottom: 45px;
  padding-left: 15px;
  position: relative;
}

.lower_card_container {
  padding-top: 80px;
  padding-bottom: 110px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(127, 161, 250, 0.15);
  border-radius: 10px;
  height: 440px;
}

.left_greeting {
  position: relative;
  width: 30%;
  font-family: 'SFProText Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 29px;
  line-height: 36px;
  color: #ffffff;
  float: left;
}

.vertical_divider {
  float: right;
  border: 1px dashed #cfd7df;
  height: 230.41px;
}

.item {
  vertical-align: top;
  display: inline-block;
  text-align: center;
}

.caption {
  padding: 20px 0 15px 0;
  font-family: 'SFProText Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #333333;

  display: block;
}

.description {
  display: block;
  font-family: 'SFProText Regular';
  font-style: normal;
  font-weight: 400;
  // font-size: 16px;
  font-size: 16px;
  line-height: 18px;
  /* or 150% */

  text-align: center;

  color: #666666;
  padding-bottom: 15px;
}

.lower_card_button {
  color: white;

  background-color: #fff;
  width: auto;
  height: 39px;
}

.upper_card_right_side {
  position: relative;
  white-space: nowrap;
  color: white;
}
.upper_card_divider {
  margin-right: 40px;
  width: 2px;
  height: 75px;
  background: #fff;
}
.upper_card_right_side_b {
  position: relative;
  white-space: nowrap;
  color: white;
  font-size: 40px;
  font-weight: 600;
}

.left_triangle {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: 6px 0 0 0;
}

.full_square {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: 0;
}

.hr_dots {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 35px 0px 0px 85px;
}

.flipped_triangle1 {
  position: absolute;
  float: right;
  top: 0;
  bottom: 0;
  right: 30px;
}

.flipped_triangle2 {
  position: absolute;
  float: right;
  top: 0;
  bottom: 0;
  right: 50px;
}

.vr_dots {
  position: absolute;
  float: right;
  top: 40px;
  bottom: 0;
  right: 30px;
}

.bt_dots {
  bottom: 6px;
  left: 50%;
  position: absolute;
}
// .inwardbutton > span{
//   margin-left: 35px;
//   margin-right: 35px;
// }
.cardbody{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border-radius: 18px;
  text-align: center;
  transform: scaleY(1);
  transition-delay: 0.3s;
  height: 246px !important;
  // box-shadow: 10px 10px red;
}
.cardbody1{
  border-radius: 18px;
}
.cardbody:hover {
  transform: scale(1.1);
}

.cardbody1 > div >div >div{
  width:100% !important;
}
.inwardbutton:hover{
  color:black !important;
  
}
.inwardbutton{
  // padding-left: 50px;
  // padding-right: 70px;
  // margin-left: -40px !important;
  border-radius: 8px;
  // font-size: 16px;
  font-size: 16px;
  background-color: #f1f1f1;
  width: 100% !important;
}
.fileback{
  width: 56px;
height: 56px;

background: #FFBAD7;
border: 10px solid #FFDEEC;
border-radius: 28px;

/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}
@media screen and (max-width: 480px) {
  .inwardbutton{
    font-size: 16px;
  }
}

.cardbody >div >div>div:nth-child(2)>span{
  // font-size: 16px;
  font-size: 16px;
  font-weight: 600;
}
.stickers{
  width: 80% !important;
}
@media screen and (max-width: 480px) {
.stickers{
  height: 40%;
  width: 40%;
  padding-top: 20px;
  padding-bottom: 20px;
}
}
.qc_stickers{
  height: 40%;
  width: 40%;
  padding-top: 20px;
  padding-bottom: 20px;
}


.container {
  width: 300px !important;
  // height: 50px !important;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 200px));
  grid-auto-rows: 150px;
  grid-auto-flow: row dense;
  grid-gap: 33px;
  justify-content: center;
}


.cardleft {
  display: flex;
  flex-wrap: nowrap;
  height: 110px !important;
  width: 300px !important;
  border-radius: 95.2380952381px;
  box-shadow: 0px 3px 9px 1px rgba(0, 10, 20, 0.2);
  background-color: #FFF3F8;
  font-weight: bold;
  font-size: 15px;
}

.cardleft {
  flex-direction: row;
  grid-column: auto/span 2;
  grid-row: auto/span 1;
}
.cardimage {
  display: flex;
  padding-left: 12px;
  background-color: #fff;
  border-radius: 95.2380952381px 0 0 95.2380952381px;
  // opacity: 0.3;
}
.cardleft .cardimage {
height: 100%;
width: 50%;
}
.cardimage img {
width: 100%;
object-fit: cover;
}
.cardleft img {
border-radius: 95.2380952381px 0 0 95.2380952381px;
}

.cardtext {
align-self: center;
padding: 23px;
}
.cardleft .cardtext {
height: auto;
width: 50%;
padding-right: 36.8px;
}

.cardtext p {
margin: 0;
line-height: 1.35em;
color: #334455;
}

body {
  margin: 23px;
  // font-family: "Trebuchet MS", sans-serif;
  font-size: 15.5px;
}

* {
  box-sizing: border-box;
}
.cardimage>div>img
{
  height:100% !important;
}