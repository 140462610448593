.radiofont > div > label{
    font-size: 14px !important;
  }
  
  .customLable{
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #263238 !important;
  }
  .CustomPanel{
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  }
  .CustomPanel > div > span {
  
    color: #C91761 !important;
  }
  .customRadio >label{
    font-size: 14px;
  }
  .customLable >div> label{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #263238 !important;
    }
    .customLable >div:nth-child(2)>div>div>div>div>span:nth-child(2) {
      font-size: 14px !important;
    }
    .customLable >div:nth-child(2)>div>div>div>div>input {
      font-size: 14px !important;
      color: #263238 !important;
    }
    .customLable >div:nth-child(2)>div:nth-child(2)>div {
      color: #C91761;
      font-size: 14px;
  
    }
    .CustomCard >div >div>div{
      color: #c91761;
      font-weight: bold;
    }
    .CustomPanel >div >div >div{
      color: #c91761;
      font-weight: bold;
    }
    .cardStyles_approver > div >div >div>span{
      color: #c91761;
      font-weight: bold;
    }
    .qrcode{
      margin-top: 23px;
    }
    .cardStyles_approver{
      border-radius: 18px;
    }
    .cardStyles_approver > div:first-child{
      border-radius: 18px 18px 0px 0px;
      background-color: #FFF3F8;
    }
    .customLable>div>div>div>div>div>input{
      border-radius: 12px;
      color: #263238 !important;
    }
    .customLable>div>div>div>div{
      border-radius: 12px;
      color: #263238 !important;
    }
    .customLable>div>div>div>div>div{
      border-radius: 12px !important;
      color: #263238 !important;
    }
    .customLable>div>div>div>input{
      border-radius: 12px;
      color: #263238 !important;
    }