.custom_table_container {
  height: 100%;
  .empty_state_text {
    color: #666687;
    margin: 20px 0 10px;
  }
  :global(.ant-table-thead) {
    :global(.ant-table-cell) {
      font-family: 'SFProText Bold';
    }
  }
  :global(.ant-table-wrapper) {
    height: 100%;

    :global(.ant-spin-nested-loading) {
      height: 100%;

      :global(.ant-spin-container) {
        height: 100%;
        display: flex;
        flex-flow: column nowrap;

        :global(.ant-table) {
          flex: auto;
          overflow: hidden;

          :global(.ant-table-container) {
            height: 100%;
            display: flex;
            flex-flow: column nowrap;

            :global(.ant-table-thead) {
              flex: none;
            }

            :global(.ant-table-tbody) {
              flex: auto;
              overflow: scroll;
              :global(.ant-table-placeholder) {
                :global(.ant-table-cell) {
                  border-bottom: none;
                }
              }
            }
          }
        }

        :global(.ant-table-pagination) {
          flex: none;
        }
      }
    }
  }
  &.no_data {
    :global(.ant-table-content) {
      height: 100%;
      table {
        height: calc(100% - 55px);
        :global(.ant-table-tbody) {
          height: 100%;
        }
      }
    }
  }
}
.custom_table_container>div{
  font-weight: 100 !important;
}