@primary-color: #fff;

.user_menu_container {
  background: @primary-color;
  border: 1px solid #fff;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 7px 10px;
  .user_menu_avatar {
    background: #a23666;
    // margin-right: 3px;
    color: #fff;
    font-size: 17px;
  }
}
.icon_color {
  color: #ffffff;
}
.customModal >div > div:nth-child(2){
  padding-top: 0px !important;
  border-radius: 15px !important;
}
.customModal >div > div:nth-child(1){
  padding-bottom: 0px !important;
  border-radius: 15px !important;
}

.customModal >div{
border-radius: 15px !important;
}
.customModal >div >div:nth-child(1){
  border-radius: 15px !important;
  }

  .gr_logo_header{
    height: 35px;
    // width: 150px; /* Adjust size as needed */
    // height: 100px; /* Adjust size as needed */
    // background-color: black; /* or any color you prefer */
    // border-radius: 50%; /* Makes it a circle */
    // position: relative;
  }
//   .gr_logo_header::before {
//     content: '';
//     position: absolute;
//     top: -20px; /* Adjust to position the leaf */
//     left: 30px; /* Adjust to position the leaf */
//     width: 40px; /* Adjust size as needed */
//     height: 20px; /* Adjust size as needed */
//     background-color: black; /* Same color as the apple */
//     border-radius: 20px 20px 0 0; /* Rounded top corners */
//     transform: rotate(-30deg); /* Tilt the leaf */
// }

// .gr_logo_header{ display:flex; align-items: center; border: 1px solid #eee; padding: 3px 3px;  background-color: #C91761; border-radius: 0 30px 30px 0; width: 155px }
// .gr_logo_header img{ margin-right: 5px; }  
// .gr_logo_header > div{ width: 35px; height: 28px; display: flex; border-radius: 50%; align-items: center; background-color: #fff; } 
// .gr_logo_header > div p{ font-size: 20px;font-weight: 700; width: 100%; text-align: center; margin-top: 17px;
//   color: black; }

  .gr_logo_header{ display:flex; align-items: center; border: 1px solid #eee; padding: 0px 0px 0px 6px;  border: 1px solid #C91761; border-radius: 10px 30px 30px 10px; width: fit-content; }
.gr_logo_header img{ margin-right: 6px; width: 60px; }  
.logosvg{  color:#C91761;  }
.gr_logo_header > div{ width: 35px; height: 35px; display: flex; border-radius: 50%; align-items: center; background-color: #C91761; } 
.gr_logo_header > div p{ font-size: 15px;font-weight: 700; width: 100%; text-align: center; color: #fff; margin-top: 15px;}