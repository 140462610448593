@primary-color: #fff;

.custom_layout_container {
  // font-family: "Source Sans Pro";
  min-height: 100vh;

  :global(.ant-layout) {
    background: #f6f6f9;
    // background: red;
  }

  .sider_container {
    overflow: auto;
    height: 83%;
    position: fixed;
    left: 20px;
    top: 100px;
    bottom: 0;

    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(44, 92, 197, 0.2);
    border-radius: 5px;
    :global(.ant-menu-vertical) {
      border: none;
    }
    :global(.ant-layout-sider-children) {
      :global(.ant-menu.ant-menu-inline-collapsed) {
        margin: 0 12px;
        background: #f3f3f4;
      }
      :global(.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)) {
        :global(.ant-menu-item) {
          margin: 0;
          background: #fff;
          border-radius: 4px;
          margin-top: 14px;
          :global(.anticon) {
            color: red;
          }
        }
        :global(.ant-menu-item-selected) {
          background: #ffffff;
          :global(.anticon) {
            color: red;
          }
        }
        
      }
    }
  }
  .sider_logo {
    height: 32px;
    margin: 16px;
  }

  .site_layout {
    .site_layout_header {
      height: 71px;
      position: fixed;
      z-index: 1;
      width: 100%;
      background: @primary-color;
      // padding: 0 10px 0 20px;
    }
    .site_layout_content {
      margin: 30px;
      margin-top: 100px;
      margin-left: 285px;
    }

    .logo_header {
      // height: 32px;
      margin: 0px;
    }
  }
}

.menu {
  background-color: @primary-color;
  display: flex;
  border-bottom: none;
  min-width: 50%;

  :global(.ant-menu-item a) {
    color: rgba(65, 54, 54, 0.85);
  }
  :global(.ant-menu-item a:active) {
    color: #fff;
  }

  :global(.ant-menu-item a:hover) {
    font-weight: 600;
    padding-right: 15px;
    border-radius: 4px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    background-color: rgb(243, 238, 238);
    border: 1px solid white;

    color: #fff;

    .icon {
      filter: brightness(0) saturate(100%) invert(0%) sepia(83%)
        saturate(5163%) hue-rotate(240deg) brightness(102%) contrast(101%);
    }
  }
}

.icon {
  padding-right: 5px;
  svg {
    margin-bottom: -3px;
  }
}

.active {
  font-weight: 600;
  padding-right: 15px;
  border-radius: 4px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  background-color: white;
  border: 1px solid white;
  color: white;

  .icon {
    filter: brightness(0) saturate(100%) invert(0%) sepia(83%) saturate(5163%)
      hue-rotate(240deg) brightness(102%) contrast(101%);
  }
}

.custom_sidebar_container {
  border-radius: 4px;
  line-height: 30px !important;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 4px;

  :global(.ant-menu-item-selected a) {
    color: #fff;
    font-weight: 800;
    border-radius: 4px;

    .side_icon {
      filter: brightness(0) saturate(100%) invert(25%) sepia(83%)
        saturate(5163%) hue-rotate(240deg) brightness(102%) contrast(101%);
    }
  }
}

:global(.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected) {
  background-color: #f3f0ff;
  border-radius: 5px;
}

.custom_layout_container1 {
  min-height: 100vh;
  
  :global(.ant-layout) {
    background: #F1F1F1;
  }
  .sider_container {
    overflow: auto;
    height: 83%;
    position: fixed;
    left: 20px;
    top: 100px;
    bottom: 0;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(44, 92, 197, 0.2);
    border-radius: 5px;
    :global(.ant-menu-vertical) {
      border: none;
    }
    :global(.ant-layout-sider-children) {
      :global(.ant-menu.ant-menu-inline-collapsed) {
        margin: 0 12px;
        background: #f3f3f4;
      }
      :global(.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)) {
        :global(.ant-menu-item) {
          margin: 0;
          background: #fff;
          border-radius: 4px;
          margin-top: 14px;
          :global(.anticon) {
            color: red;
          }
        }
        :global(.ant-menu-item-selected) {
          background: #ffffff;
          :global(.anticon) {
            color: red;
          }
        }
      }
    }
  }
  .sider_logo {
    height: 32px;
    margin: 16px;
  }

  .site_layout {
    .site_layout_header {
      height: 71px;
      position: fixed;
      z-index: 1;
      width: 100%;
      background: @primary-color;
      top:0px !important;
      // padding: 0 10px 0 20px;
    }
    .site_layout_content {
      margin: 5%;
      margin-top: 115px;
    }

    .logo_header {
      height: 35px;
      margin-left: 20px;
    }
    .gr_logo_header{
      height: 25px;
    }
  }
}
@media screen and (max-width: 480px) {
  .custom_layout_container1
  {
    .site_layout
    {
      .logo_header 
      {
        height: 22px;
        margin-left: 0px;
      }
    }
  }
}


.radiofont > div > label{
  font-size: 14px !important;
}

.customLable{
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 20px;
color: #263238;
border-radius: 18px !important;

}
.customLable>div>div>div>div>div{
  border-radius: 12px !important;
  color:black !important;
}
.customLable>div>div>div>div>div>div{
  color:black !important;
}
.customLable>div>div>div>input{
  border-radius: 12px !important;
  color:black !important;
}
.customLable>div>div>div>div{
  border-radius: 12px !important;
  color:black !important;
}
.CustomPanel{
font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 20px;
}
.CustomPanel > div > span {

  color: #C91761 !important;
}
.customRadio >label{
  font-size: 14px;
}
.customLable >div> label{
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #263238;
  }
  .customLable >div:nth-child(2)>div>div>div>div>span:nth-child(2) {
    font-size: 14px !important;
    color: black !important
  }

  .customLable12 >div:nth-child(2)>div>div>span {
    font-size: 14px !important;
    color: black !important
  }

  .customLable >div:nth-child(2)>div:nth-child(2)>div {
    color: #C91761;
    font-size: 14px;

  }
  .CustomCard >div >div>div{
    color: #c91761;
    font-weight: bold;
  }
  .CustomPanel >div >div >div{
    color: #c91761;
    font-weight: bold;
  }
  .cardStyles_approver > div >div >div>span{
    color: #c91761;
    font-weight: bold;
  }
  .cardStyles_approver{
    border-radius: 18px;
  }
  .cardStyles_approver>div:first-child{
    border-radius: 18px 18px 0px 0px;
    background-color: #FFF3F8;
  }
  .cardStyles_approver1{
    border-radius: 18px;
  }
  .radiofont > div > label{
    font-size: 14px !important;
  }
  
  .customLable{
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #263238;
  }
  .CustomPanel{
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  border-radius: 18px !important;
  }
  .panelborder>div{
    border-radius: 18px !important;
  }
  
  .CustomPanel>div{
    border-radius: 18px;
  }
  .CustomPanel > div > span {
  
    color: #C91761 !important;
  }
  .customRadio >label{
    font-size: 14px;
  }
  .customLable >div> label{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 125% */
    
    
    color: #263238 !important;
    }
    .customLable >div:nth-child(2)>div>div>div>div>span:nth-child(2) {
      font-size: 14px !important;
      color: black !important;
    }
    .customLable >div:nth-child(2)>div>div>div>div>input {
      font-size: 14px !important;
      color: black !important;
    }
    .customLable >div:nth-child(2)>div:nth-child(2)>div {
      color: #C91761;
      font-size: 14px;
  
    }
    .CustomCard >div >div>div{
      color: #c91761;
      font-weight: bold;
    }
  
    .CustomPanel>div:first-child{
      border-radius: 18px 18px 0px 0px !important;
      background-color: #FFF3F8;
    }
    .CustomPanel>div:nth-child(2){
      border-radius: 18px !important;
    }

    .searchbar>span>input{
      border-radius: 18px 0px 0px 18px;
      margin-right: 86px !important;
    }
    // .searchbar>span>input::after{
    //   border-radius: 18px;
    // }
    .searchbar>span>span>button{
      // display:none;
      border-radius: 0px 18px 18px 0px !important;
    }
    .cardStyles_approver{
      border-radius: 18px;
    }
    .cardStyles_approver>div:first-child{
      border-radius: 18px 18px 0px 0px;
      background-color: #FFF3F8;
    }
    .cardStyles_approver>div:first-child>div>div{
      font-weight: bold;
      color: #c91761;
    }
    .border_button{
      border-radius: 8px;
      // margin-top: 26px;
    }
    .border_button1{
      border-radius: 8px;
      margin-top: 26px;
    }

    // .qr{
    //   border-radius: 18px;
    // }
    @media (max-width: 768px) {
      .qr{
        margin-left: -12px;
        border: 0px !important;
      }
      .removal{
        margin-top: 12px !important;
        // margin-left: -1px !important;
        font-size: 14px !important;
        text-align: center;
      }
    }

    .customValue{
      color: #858C94;
      font-size: 14px;
    }
  .lable{
    font-weight: bold;
  }
  .lableValue{
    background-color: whitesmoke;
    border: 1px solid #b3b0b0;
    width: 100%;
    border-radius: 4px;
    text-align: center;
    padding: 5px;
  }
  .lableValue2{
    // background-color: whitesmoke;
    border: 1px solid #b3b0b0;
    width: 100%;
    border-radius: 4px;
    text-align: center;
    padding: 5px;
  }
  .lableValue1{
    background-color: whitesmoke;
    border: 1px solid #b3b0b0;
    width: 100%;
    border-radius: 4px;
    text-align: center;
    padding: 5px;
    margin-top: -8px;
  }
  @media (max-width: 768px) {
    .qr{
      margin-left: -12px;
      border: 0px !important;
    }
    .removal{
      margin-top: 12px !important;
      // margin-left: -1px !important;
      font-size: 14px !important;
      text-align: center;
    }
  }
  .customInput{
    margin-top: -20px;
  }
  .table_container {
    margin-top: 16px;
    margin-left: 0px !important;
  }
  .table_container th {
    font-size: 14px;
    //
  }
  .table_container td {
    font-size: 14px;
  }
  .customModal >div>div>div{
    font-size: 14px !important;
  }
  .customModal >div > div:nth-child(2){
    padding-top: 0px !important;
    border-radius: 15px !important;
  }
  .customModal >div{
    border-radius: 15px !important;
  }
  .cardStyles_approver>div>div>div{
    color: #c91761;
    font-weight: bold;
  }
  @media screen and (max-width: 480px) {
    .direction2 {
      flex-direction: column;
    }
  }
  .selectContainers{
    height: 50px;
  }
  .selectContainersLable{
    margin-top: -11px;
  }
  .selectContainersLable1{
    margin-top: 5px;
  }
  .cardStyles_approver{
    border-radius: 18px !important;
  }
  .cardStyles_approver > div:first-child{
    border-radius: 18px 18px 0px 0px;
    background-color: #FFF3F8;
  }
  .customLable1{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #263238;
    margin-top: -14px;
    border-radius: 12px;
    
  }
  .customLable1>div>div>div>div{
    border-radius: 12px;
  }

  .customLable>div>div>div{
    border-radius: 12px;
  }
  .uom{
    margin-top: 23px;
  }
  .customModal>div>div>div{
    font-size: 17px !important;
    color: #c91761;
    font-weight: bold;
  }
  .SubPanel{
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    border-radius: 18px !important;
  }

  .SubPanel>div>div{
    color: black;
    font-weight: bold;
  }
  .SubPanel>div:first-child{
    border-radius: 18px 18px 0px 0px!important;
    background-color: #ece7e7;
  }
  .tagStyle{
    color:#c91761 !important;
    font-size: 14px !important;
    // padding: 3px;
  }
 
  .CustomPanel1{
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    border-radius: 18px !important;
    }
    .CustomPanel1 > div > span {

      color: #C91761 !important;
    }
    .CustomPanel1>div:first-child{
      border-radius: 18px !important;
      background-color: #FFF3F8;
    }
    .CustomPanel2>div{
      border-radius: 18px;
      
    }
    .CustomPanel2>div:first-child{
      border-radius: 18px 18px 0px 0px !important;
      background-color: #FFF3F8;
    }
    .CustomPanel2>div:nth-child(2){
      border-radius: 0px 0px 18px 18px !important;
    }
    .CustomPanel2>div>span>div>div>label{
      color:#C91761
    }
    .CustomPanel2>div>span{
      color:#C91761
    }
    .paragraph{
      margin-bottom: 6px !important;
    }
    .unorderlist{
      margin-bottom: 2px !important;
      font-weight: 100;
      font-size: 14px !important;
    }
    .tagStyle1{
      color: #ffffff !important;
    font-weight: bold;
    background-color: #c91761;
    top:27px !important;
    }
    .gap{
      margin-top: 10px !important;
    }
    .tagStyle2{
      color: #ffffff !important;
      font-weight: bold;
      background-color: #c91761;
      top:17px !important;
    }
    .inwardButton1{
      font-size: 13px !important;
    }
    .dividerstyles>div{
      margin: 6px 0px !important;
    }
    .updateProcess{
      padding-top: 4px !important;
      padding-bottom: 4px !important;
      border-radius: 18px !important;
      // font-size: 12px;
      width:230px
    }
    .buttonStyle{
      border-radius: 18px;
      border-color: #c91761;
      color: #fff;
      background-color: #c91761;
      cursor: pointer;
      font-weight:100;
    }
    .CustomPanel11 >div>div>div{
      margin-top: 0px !important;
    }
    .CustomPanel11{
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      border-radius: 18px !important;
      }
      .CustomPanel11 > div > span {

        color: #C91761 !important;
      }
      .CustomPanel11>div:first-child{
        border-radius: 18px !important;
        background-color: #FFF3F8;
      }

      .customLable11{
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 2px;
        color: #263238;
        border-radius: 18px !important;
        margin-bottom: 11px !important;
        }
        .customLable11>div>div>div>div>div{
          border-radius: 12px !important;
        }
        .customLable11>div>div>div>input{
          border-radius: 12px !important;
        }
        .customLable11>div>div>div>div{
          border-radius: 12px !important;
        }
        .customLable11>div>label{
          font-size: 12px !important;
        }
        .CustomPanel11{
          border-radius: 18px;
        }
        .subpanel{
          border-radius: 18px;
        }
        .cardStyles_approver2{
          border-radius: 18px !important; 
          overflow: hidden;
          background-color: #6c6c7066;
        }
        
        .cardStyles_approver1 >{
      // border-radius: 18px;
        }
        .cardStyles_approver1 > div:first-child{
          border-radius: 18px 18px 0px 0px !important;
          background-color: #FFF3F8;
        }
       

        .cardStyles_approver12 > div >div >div>span{
          color: #c91761;
          font-weight: bold;
        }
        .cardStyles_approver12{
          border-radius: 18px;
        }
        .cardStyles_approver12>div:first-child{
          border-radius: 18px 18px 0px 0px;
          // background-color: #FFF3F8;
        } 
        .cardStyles_approver13{
          margin-left: 80px;
        }
        
        .customLable>:nth-child(2)>div>div>textarea{
          border-radius: 18px !important;
        }
        .customModal>div>div>form>div{
          border-radius: 18px !important;
        }
        .SubPanel3{
          border-radius: 18px !important;
        }
        .SubPanel2{
          border-radius: 18px !important;
          background-color: #FFF3F8;
        }
        .SubPanel21{
          border-radius: 18px !important;
          background-color: #FFF3F8;
          overflow: hidden;
        }
        .SubPanel22{
          border-radius: 18px !important;
          // background-color: #FFF3F8;
          overflow: hidden;
        }
        .SubPanel22>div:nth-child(1){
          background-color: #FFF3F8;
        }

        .customLable9>div:first-child{
          border-radius: 18px 18px 0px 0px;
        }
        .customLable9>div:nth-child(2){
          border-radius: 0px 0px 18px 18px;
        }
        .tagfont{
          font-weight: 100;
          font-size: 18px !important;
          margin-top: 5px;
        }
        // .CustomPanel2 > div:nth-child(2){
        //   margin-top: 12px;
        // }

       
          .CustomPanelFG >div >div >div{
            color: #c91761;
            font-weight: bold;
          }
          .CustomPanelFG{
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 20px;
            border-radius: 18px !important;
            }
            // .CustomPanelFG>div{
            //   background-color: #80808024;
            //   border-radius: 18px 0px 18px 0px;
            // }
            .CustomPanelFG>div:first-child{
              border-radius: 18px 18px 0px 0px !important;
              background-color: #80808024;
            }

            .CustomPanelFG1{
              border-radius: 18px  !important;
              // background-color: #80808024;
            }
           
.blink {
  color : rgb(244, 242, 242);
  margin-right: 0.5rem;
    animation: blink 2s steps(5, start) infinite;
    -webkit-animation: blink 1s steps(5, start) infinite;
  }
  @keyframes blink {
    to {
      visibility: hidden;
    }
  }
  @-webkit-keyframes blink {
    to {
      visibility: hidden;
    }
  }
  .main_img{
    margin-left: 0px !important;
  }
  .plusButton{
    margin-top: 27px !important;
    font-size: 18px !important;
    color: #C91761;
  }
  .verificaton_button{
    float: right !important;
    margin-top: -8px !important;
    cursor: pointer;
  }
  .plusCircle{
    padding-top: 36px !important;
  }
  .plusCircle1{
    padding-top: 36px !important;
    font-size: 25px !important;
    color: #C91761
  }
  .plusCircle2{
    margin-top: 36px !important;
  }
  .divider{
    margin-top: 1px !important;
    margin-bottom: 10px !important;
  }
  .upload >div{
    width: 100% !important;
    border-radius: 8px;
    
  }
  .customTab>div>div>div>div{
    color: #867c7c;
  }
  .customTab>div>div>div>div:active{
    // background-color: red;
  }
  
  .cardStyles_approver1111 > div:first-child{
          border-radius: 18px !important;
          background-color: #f0f0f0;
  }
  .inwardButton_approve{
    background-color: #28a745;
    border-color: #28a745;
    color: #fff;
  }
  .inwardButton_reject{
    background-color: #dc3545;
    border-color: #dc3545;
    color: #fff;
    
  }
  .buttons{
    margin-top: 23px;
  }