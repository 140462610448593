.page_title{
  color: #c91761;
  font-weight: 700;
}
.button_import_style {
  label {
    font-size: 14px !important;
    font-weight: 600 !important;
    height: 34px !important;
    padding: 0px 15px !important; //Tab padding
    line-height: 32px !important;
    background-color: #fefefe;
    color: #344054;
    font-family: "Source Sans Pro";
  }
  label:hover {
    // font-size: 14px !important;
    // font-weight: 600 !important;
    // height: 31px !important;
    // padding: 0px 15px !important; //Tab padding
    // line-height: 27px !important;
    background-color: #f0f0ff;
    color: #c91761;
  }
}
.Gate_pass_req_title{
  font-weight: bold;
  font-size: 16px;
}
.Gate_pass_req_sub_title{
  font-size: 14px;
}

.cardStyles_approver{
  border-radius: 18px;
}

.cardStyles_approver > div>div>div>div>div{
  border-radius: 18px;
  // background-color: red;
}
.badgestyles{
  height: 14px !important;
font-size: 14px !important;
background-color: #cbdfff;
color: #1c4e9d;
font-weight: 500;

padding-top: 2px;
padding-bottom: 15px;
}
.customButton_style_secondary{
  font-size: 12.5px !important;
font-weight: 400 !important;
background-color: white;
color: rgb(90, 82, 82);
height: 32px !important;
border-color: #6a6b6c;
border-radius: 18px;
}
.searchbar > div > span {
  font-size: 14px !important;
}
.searchwidth{
  width:98% !important;
}
.table_container {
  margin-top: 16px;
  margin-left: 0px !important;
}
.table_container th {
  font-size: 14px;
  //
}
.table_container td {
  font-size: 14px;
}
// @media (min-width: 1440px) {
.custombuttons > div {
position: relative;
float: right;
// }
}
.custombuttons{
padding-right: 0px !important;
}
// @media (min-width: 540px) {
//   .scrolling {
//     overflow: auto;
//   }
// }
@media (max-width: 767px) {
.custombuttons span:nth-child(2) {
  display: none;
}
}
.CustomPanel >div >div >div{
  color: #c91761;
  font-weight: bold;
}
.CustomPanel>div:first-child{
  border-radius: 18px 18px 0px 0px;
  background-color: #fff3f8;
}
.CustomPanel{
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  border-radius: 18px;
  }
  .CustomPanel > div > span {
  
    color: #C91761 !important;
  }