.radiofont > div > label{
    font-size: 14px !important;
  }
  
  .customLable{
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #263238;
  }
  .CustomPanel{
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  }
  .CustomPanel > div > span {
  
    color: #C91761 !important;
  }
  .customRadio >label{
    font-size: 14px;
  }
  .customLable >div> label{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #263238;
    }
    .customLable >div:nth-child(2)>div>div>div>div>span:nth-child(2) {
      font-size: 14px !important;
    }
    .customLable >div:nth-child(2)>div:nth-child(2)>div {
      color: #C91761;
      font-size: 14px;
  
    }
    .CustomCard >div >div>div{
      color: #c91761;
      font-weight: bold;
    }
    .CustomPanel >div >div >div{
      color: #c91761;
      font-weight: bold;
    }
    .cardStyles_approver > div >div >div>span{
      color: #c91761;
      font-weight: bold;
    }
    .qrcode{
      margin-top: 23px;
    }
    .table_row td {
      font-size: 14px;
    }
    .table_row tr {
      line-height: 0.3px;
    }    
    .heading1{
      margin-left: 10px;
    }
    .heading1 > h1{
      color: #c91761;
      font-weight: bold;
      padding-bottom: 0px;
      margin-bottom: 0px;
    }
    .heading1 > h4{
      margin-top: 0px;
      padding-top: 0px;
      padding-bottom: 10px;
    }
    
  // .table_container {
  //   margin-top: 16px;
  //   margin-left: 0px !important;
  // }
  // .table_container th {
  //   font-size: 14px;
  //   //
  // }
  // .table_container tr {
  //  line-height: 3px;
  // }
  .customModal >div > div:nth-child(2){
    padding-top: 0px !important;
  }
  .customModal >div > div:nth-child(1){
    padding-bottom: 0px !important;
  }
  .cardStyles_approver{
    border-radius: 18px;
  }
  .cardStyles_approver>div:first-child{
    border-radius: 18px 18px 0px 0px;
    background-color: #fff3f8;
  }